import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import axios from "./axios";

import preview from 'vue-photo-preview'

import 'vue-photo-preview/dist/skin.css'
Vue.config.productionTip = false
let options = {
  fullscreenEl: false,
}
Vue.use(preview, options)
Vue.use(preview)
Vue.prototype.$ajax = axios;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
